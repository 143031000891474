import axios, { AxiosRequestConfig } from 'axios';

export const getLocationDetails = async (query: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    method: 'GET',
    url: process.env.REACT_APP_OUTSCRAPER_ENDPOINT,
    params: {
      query,
      async: false,
      sort: 'lowest_rating',
      cutoffRating: 4,
      reviewsLimit:0,
    },
    headers: {
      'X-API-KEY': process.env.REACT_APP_OUTSCRAPER_KEY
    }
  };

  try {
    let response:any;
    if(!sessionStorage.getItem(query)) {
      response = await axios.request(options)
      sessionStorage.setItem(query,JSON.stringify(response))
    }else {
      const cacheData:any = sessionStorage.getItem(query)
      response = JSON.parse(cacheData)
    }
    const locationData = response?.data?.data[0]
    const totalReviews = locationData.reviews
    const star_1_reviews = locationData.reviews_data.filter((data:any) => data.review_rating === 1).length
    const star_2_reviews = locationData.reviews_data.filter((data:any) => data.review_rating === 2).length
    const star_3_reviews = locationData.reviews_data.filter((data:any) => data.review_rating === 3).length
    const star_4_reviews = locationData.reviews_data.filter((data:any) => data.review_rating === 4).length
    const star_5_reviews = totalReviews - (star_1_reviews+star_2_reviews+star_3_reviews+star_4_reviews)
    const reviewObj = {
      1:star_1_reviews,
      2:star_2_reviews,
      3:star_3_reviews,
      4:star_4_reviews,
      5:star_5_reviews
    }
    locationData.reviews_per_score = reviewObj
    return {
      locationData,
      reviewsData:locationData.reviews_data
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};
