import axios, { AxiosRequestConfig } from 'axios';

export const getLocationReviewDetails = async (query: string,location?:any): Promise<any> => {

  const currentDate = new Date();
  const fourYearsBackDate = new Date();
  fourYearsBackDate.setFullYear(currentDate.getFullYear() - 4);

  const options: AxiosRequestConfig = {
    method: 'GET',
    url: process.env.REACT_APP_OUTSCRAPER_ENDPOINT,
    params: {
      query,
      async: false,
      sort: 'lowest_rating',
      cutoffRating: 3,
      reviewsLimit:0
    },
    headers: {
      'X-API-KEY': process.env.REACT_APP_OUTSCRAPER_KEY
    }
  };

  try {
    let data;
    if(location){
      data = location.reviews_data.filter((review:any) => review.review_rating !== 4)
    }else{
      const response = await axios.request(options);
      data = response.data.data[0].reviews_data;
    }

    data.sort((a: any, b: any) => new Date(a.review_datetime_utc).getTime() - new Date(b.review_datetime_utc).getTime());

    const oldestReview = data[0] || null;
    if (!oldestReview) return {
      locationData: location,
      noReviewFound: true
    }

    const oldestReviewYear = new Date(oldestReview.review_datetime_utc).getFullYear();

    const oldestYearReviews = data.filter((review: any) => new Date(review.review_datetime_utc).getFullYear() === oldestReviewYear);

    const groupedReviews: { [key: number]: any[] } = {};
    let accumulatedReviews: any[] = [];

    data.forEach((review: any) => {
      const year = new Date(review.review_datetime_utc).getFullYear();
      accumulatedReviews.push(review);
      if (!groupedReviews[year]) groupedReviews[year] = [];
      groupedReviews[year] = [...accumulatedReviews];
    });

    return {
      oldestReview,
      oldestReviewYear,
      oldestYearReviewCount: oldestYearReviews.length,
      locationData: location,
      groupedReviews
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};
